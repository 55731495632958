import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowForward';

export const StepFour = ({messageId="success", email=""}) => {

  return (
    <Box
    sx={{
        mx: {
          xs: 2,
          sm: 4,
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: {
          xs: 'flex-start',
          sm: 'center',
        
        },
        height: {
          xs: 'auto',
          sm: '100%',        
        },
    }}
    >
      <Stack spacing={2} alignItems={"center"}>
        <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M29.9733 4.09551C30.3129 3.96816 30.6871 3.96816 31.0267 4.09551L51.0267 11.5955C51.6121 11.8151 52 12.3747 52 13V30.5C52 38.6697 46.5774 45.3004 41.4878 49.7539C38.9114 52.0082 36.3422 53.7738 34.42 54.9751C33.4573 55.5768 32.653 56.0394 32.0858 56.3534C31.8022 56.5104 31.5776 56.6304 31.4219 56.7121C31.344 56.753 31.2833 56.7843 31.2411 56.806L31.1917 56.8311L31.1776 56.8382L31.1718 56.8411C31.1718 56.8411 31.1708 56.8416 30.5 55.5C29.8292 56.8416 29.8282 56.8411 29.8282 56.8411L29.8224 56.8382L29.8083 56.8311L29.7589 56.806C29.7167 56.7843 29.656 56.753 29.5781 56.7121C29.4224 56.6304 29.1978 56.5104 28.9141 56.3534C28.347 56.0394 27.5427 55.5768 26.58 54.9751C24.6578 53.7738 22.0886 52.0082 19.5122 49.7539C14.4226 45.3004 9 38.6697 9 30.5V13C9 12.3747 9.38786 11.8151 9.97331 11.5955L29.9733 4.09551ZM30.5 55.5L29.8292 56.8416C30.2515 57.0528 30.7485 57.0528 31.1708 56.8416L30.5 55.5Z" fill="#2970FF"/>
          <path d="M30.1576 30.4388H23L29.1515 19H36.3009L30.1576 30.4388Z" fill="white"/>
          <path d="M31.1393 41.8777H23.9816L30.1331 30.439H37.2907L31.1393 41.8777Z" fill="white"/>
        </svg>
        {messageId === "success" && (
          <>
          <Typography component="h1" variant="h5" fontWeight={700}>
              Thank you for signing up!
          </Typography>
          <Typography variant="text" color="#6C737F" textAlign={"center"}>
              You can now login to Keystrike and get everything setup to secure your organization.
          </Typography>
          </>
        )}
        {messageId === "org_already_registered" && (
          <>
          <Typography component="h1" variant="h5" fontWeight={700}>
              Organization already exists!
          </Typography>
          <Typography variant="text" color="#6C737F" textAlign={"center"}>
              It seems that we already have an organization registered for domain {email.split('@')[1]}.
              Please contact your organization's IT support to get access to Keystrike.
          </Typography>
          </>
        )}
        {messageId === "admin_already_registered" && (
          <>
          <Typography component="h1" variant="h5" fontWeight={700}>
              Account already exists!
          </Typography>
          <Typography variant="text" color="#6C737F" textAlign={"center"}>
              It seems that we already have an account registered for email {email}.
          </Typography>
          <Typography variant="text" color="#6C737F" textAlign={"center"}>
              Please <a href="https://admin.keystrike.com">login to Keystrike</a> to continue.
          </Typography>
          <Typography variant="text" color="#6C737F" textAlign={"center"}>If you have forgotten your password, you can reset it from our login page.
          </Typography>
          <Typography variant="text" color="#6C737F" textAlign={"center"}>If you have issues with access to your organization in Keystrike, please contact your organization's IT support to get access to Keystrike.
          </Typography>
          </>
        )}
        {messageId === "create_in_saas_failed" && (
          <>
          <Typography component="h1" variant="h5" fontWeight={700}>
            Thank you for signing up!
          </Typography>
          <Typography variant="text" color="#6C737F" textAlign={"center"}>
            We have received your request to sign up to Keystrike. Our team will review your request as soon as possible.
            We will send an activation email to {email} once your request is approved. 
          </Typography>
          </>
        )}
        <Button
            type="submit"
            fullWidth
            variant="contained"
            href='https://admin.keystrike.com'
            sx={{ 
                mt: 3, 
                mb: 2, 
                bgcolor: '#2970FF', 
                color: 'white', 
                fontWeight: 600,
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '12px',
                height: '42px',
            }}
            endIcon={<ArrowRight />}
            >
            Login to Keystrike
        </Button>
      </Stack>
    </Box>
  );
}
import './App.css';
import SignUp from './components/SignUp';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  ReactGA.initialize('G-8QERXNZ66X')
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignUp />} />
      </Routes>
    </Router>
  );
}

export default App;
